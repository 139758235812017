/* eslint-disable */
import {ColumnConfig, IColumnConfig} from './columnConfig';
import { ColumnTemplateType } from './enums';

export class CatalogueColumnConfig extends ColumnConfig {

  modelIdField!: string;
  modelDescField! : string;
  keyField!: string;
  displayDescField?: string;
  catalogueComponentName!: string;

  constructor(config: ICatalogueColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.Catalogue;
    super(config);
    this.modelIdField = config.modelIdField;
    this.modelDescField = config.modelDescField;
    this.keyField = config.keyField;
    this.displayField = config.displayField;
    this.displayDescField = config.displayDescField;
    this.catalogueComponentName = config.catalogueComponentName;
  }
}

export interface ICatalogueColumnConfig extends IColumnConfig {
  modelIdField: string;
  modelDescField: string;
  keyField: string;
  displayField: string;
  displayDescField?: string;
  catalogueComponentName: string;
}
